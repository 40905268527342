<template>
    <div>
        <button
            class="w-max border border-solid rounded-lg px-2 py-1 text-sm focus:outline-none h-8 inline-block items-center justify-center"
            :class="{
                'bg-black text-white border-black': layer.visible,
                'bg-white text-black border-gray-500': !layer.visible,
            }"
            @click="onToggleVisibility"
        >
            <div class="flex gap-2 justify-center items-center">
                <i v-if="icon" class="mt-1" :class="icon"></i>
                <span v-if="!hideLayerName" class="whitespace-nowrap">{{ layer.name }}</span>
            </div>
        </button>

        <commute-radius-layer
            v-if="showCommuteRadiusToggle"
            :layer="layer"
            :map="map"
            @close="showCommuteRadiusToggle = false"
        />

        <component v-if="isVisible" :is="mapComponent" :layer="layer" :map="map" />
        <component v-if="searchComponent != null" :is="searchComponent" :layer="layer" :map="map" />
    </div>
</template>

<script>
import { get } from 'utils/lodash';
import { mapActions, mapGetters } from 'vuex';
import CommuteRadiusLayer from 'components/sharable-map/layers/layer/commute-radius/index.vue';

export default {
    components: {
        CommuteRadiusLayer,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        hideLayerName: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        showCommuteRadiusToggle: false,
    }),
    computed: {
        ...mapGetters('map', ['isPublic']),
        isVisible() {
            return get(this.layer, 'visible', true);
        },
        mapComponent() {
            return () => import(/* webpackChunkName: "layer-map" */ `components/layers/types/${this.layer.type}/map`);
        },
        searchComponent() {
            try {
                return require(`@/components/layers/types/${this.layer.type}/search.vue`).default;
            } catch (e) {
                return null;
            }
        },
    },
    methods: {
        ...mapActions('map', ['saveLayer', 'updateLayer']),
        async onToggleVisibility() {
            if (this.layer.type === 'commute_radius') {
                this.showCommuteRadiusToggle = true;
                return;
            }

            if (this.isPublic) {
                await this.updateLayer({
                    ...this.layer,
                    visible: !this.isVisible,
                });
            } else {
                await this.saveLayer({
                    ...this.layer,
                    visible: !this.isVisible,
                });
            }
        },
    },
};
</script>
