<template>
    <div class="flex gap-2 items-center justify-start w-full">
        <p class="text-sm font-bold">Layers:</p>
        <div class="overflow-x-auto overflow-y-hidden flex gap-2 h-8 items-center">
            <layer v-for="layer in layers" :key="layer.id" :layer="layer" :map="map" />
        </div>
    </div>
</template>

<script>
import Layer from 'components/sharable-map/layers/layer/index';
export default {
    components: {
        Layer,
    },
    props: {
        map: {
            type: Object,
            required: true,
        },
        layers: {
            type: Array,
            required: true,
        },
    },
};
</script>
