<template>
    <wrapper>
        <r-loading-bounce v-if="loading" class="h-100" />
        <div class="flex flex-col">
            <div
                class="flex flex-col-reverse gap-2 md:flex-row justify-between p-4 items-start md:items-center gap-4 md:gap-40 z-10 bg-white"
            >
                <layers v-if="mapBox" :map="mapBox" :layers="visibleLayers" />
                <a href="//relocity.com">
                    <img src="https://cdn.relocity.com/assets/images/relocity-logo.svg" alt="Relocity Logo" />
                </a>
            </div>
            <div>
                <layer
                    v-if="commuteRadiusLayer && mapBox"
                    class="absolute m-4 z-10"
                    :layer="commuteRadiusLayer"
                    :map="mapBox"
                    :hide-layer-name="true"
                    icon="fas fa-layer-group"
                ></layer>
                <map-component :map-id="map" :show-nav-control="false" />
            </div>
            <mini-view
                v-if="showMiniView"
                :selected-row="selectedRow"
                @close="onDeselectMarker"
                @showFullView="onShowFullView"
                @open-template="onOpenTemplate"
                class="px-3"
            />
            <full-view v-if="showFullView" :selected-row="selectedRow" @close="onDeselectMarker" class="z-10" />
            <div
                v-if="selectedRow && templateComponent && selectedRowCategory"
                class="absolute right-0 md:mr-2 md:py-6 flex flex-col justify-center h-full w-full md:w-1/4 z-10"
            >
                <div
                    class="h-full md:h-auto w-full bg-white rounded rounded-lg shadow-2xl overflow-y-auto"
                    style="height: 80%"
                >
                    <r-icon
                        class="absolute right-0 m-6"
                        name="times"
                        icon-style="fas"
                        size="lg"
                        @click="onDeselectMarker"
                    />
                    <component
                        :is="templateComponent"
                        :record="selectedRow"
                        :category="selectedRowCategory"
                        :show-close="true"
                        viewing-from="map"
                        @close="onDeselectMarker"
                    />
                </div>
            </div>
        </div>
    </wrapper>
</template>

<script>
import store from 'store';
import { head, isEmpty, filter, includes } from 'utils/lodash';
import { mapActions, mapGetters } from 'vuex';
import Wrapper from 'layouts/main';
import MapComponent from 'components/maps/map';
import Layer from 'components/sharable-map/layers/layer/index';
import Layers from 'components/sharable-map/layers/index';
import MiniView from 'components/sharable-map/mini-view/index';
import FullView from 'components/sharable-map/full-view';

export default {
    components: {
        Layer,
        Layers,
        Wrapper,
        MiniView,
        FullView,
        MapComponent,
    },
    props: {
        map: {
            type: [String, Number],
            required: true,
        },
    },
    data: () => ({
        loading: true,
        mapBox: null,
        selectedRow: null,
        showMiniView: false,
        showFullView: false,
        templateComponent: null,
        category: null,
    }),
    computed: {
        ...mapGetters('map', ['title']),
        ...mapGetters('map', ['layers']),
        ...mapGetters('categories', ['findCategoryBySlug']),
        isMobileLayoutView() {
            return this.$mq == 'md';
        },
        visibleLayers() {
            return this.layers.filter(
                (layer) =>
                    (layer.type !== 'data_content' && layer.type !== 'commute_radius') ||
                    (layer.type === 'data_content' && this.findCategoryBySlug(layer.category)?.active === true)
            );
        },
        commuteRadiusLayer() {
            return this.layers.find((layer) => layer.type === 'commute_radius');
        },
        selectedRowCategory() {
            return this.findCategoryBySlug(this.selectedRow?.properties?.category_slug);
        },
    },
    watch: {
        map: {
            immediate: true,
            handler: 'fetchMapData',
        },
    },
    metaInfo() {
        return {
            title: this.title,
        };
    },
    created() {
        this.setPublic(true);
        this.getAllCategories();
        this.$events.$on('map:loaded', this.onMapLoaded);
        this.$events.$on('map:marker:click', this.onMarkerClicked);
        this.$events.$on('map:polygon:click', this.onPolygonClicked);
        this.$events.$on('map:marker:deselect', this.onDeselectMarker);
    },
    beforeDestroy() {
        this.$events.$off('map:loaded', this.onMapLoaded);
        this.$events.$off('map:marker:click', this.onMarkerClicked);
        this.$events.$off('map:polygon:click', this.onPolygonClicked);
        this.$events.$off('map:marker:deselect', this.onDeselectMarker);
    },
    methods: {
        ...mapActions('map', ['loadMap', 'setPublic']),
        ...mapActions('categories', { getAllCategories: 'all' }),
        resizeMap() {
            this.$nextTick(() => this.mapBox.map.resize());
        },
        async fetchMapData() {
            await this.loadMap({
                map: this.map,
            });

            this.loading = false;
        },
        onMapLoaded(mapBox) {
            store.map = mapBox.map;
            this.mapBox = mapBox;

            this.registerEvents();
        },
        registerEvents() {
            let map = this.mapBox.map;
            map.on('styleimagemissing', (e) => {
                map.loadImage(
                    'https://' + process.env.VUE_APP_RELOCITY_CDN + `/assets/map-icons/${e.id}.png`,
                    (error, image) => {
                        if (error) throw error;
                        map.addImage(e.id, image);
                    }
                );
            });
            map.on('click', (e) => {
                let features = filter(
                    map.queryRenderedFeatures([
                        [e.point.x - 15, e.point.y - 15],
                        [e.point.x + 15, e.point.y + 15],
                    ]),
                    (feature) => {
                        return (
                            includes(feature.layer.id, 'SCHOOLS') ||
                            includes(feature.layer.id, 'AREA_TOUR') ||
                            includes(feature.layer.id, 'CUSTOM') ||
                            includes(feature.layer.id, 'COMMUTE_RADIUS') ||
                            includes(feature.layer.id, 'DESTINATION') ||
                            includes(feature.layer.id, 'DATA_CONTENT')
                        );
                    }
                );

                let feature = head(features);

                if (!isEmpty(feature)) {
                    if (includes(feature.layer.id, 'COMMUTE_RADIUS')) {
                        this.$events.$emit('map:polygon:click', feature, e.lngLat);
                    } else if (includes(feature.layer.id, 'DESTINATION')) {
                        this.$events.$emit('map:destination:click', feature, e.lngLat);
                    } else {
                        this.$events.$emit('map:marker:click', feature);
                    }
                }
            });
        },
        onMarkerClicked(feature) {
            this.onDeselectMarker();
            if (!isEmpty(feature.properties)) {
                this.selectedRow = feature;

                let map = this.mapBox.map;

                if (map.getZoom() < 14) {
                    map.flyTo({
                        center: feature.geometry.coordinates,
                        speed: 2,
                        zoom: 14,
                    });

                    map.once('zoomend', () => this.mapBox.map.resize());
                }

                if (!feature.properties.cluster) {
                    this.showMiniView = true;
                }
            }
        },
        onPolygonClicked(feature, coordinates) {
            if (!isEmpty(feature.properties)) {
                let popupText = `${feature.properties.contour} mins`;
                this.$events.$emit('map:popup:enter', {
                    coordinates: coordinates,
                    html: `<div><small>Commute</small></div><b>${popupText}</b>`,
                    options: { closeOnMove: true, closeButton: false },
                });
            }
        },
        onDeselectMarker() {
            this.showFullView = false;
            this.showMiniView = false;
            this.selectedRow = null;
            this.templateComponent = null;
        },
        onShowFullView() {
            this.showMiniView = false;
            this.showFullView = true;
        },
        onOpenTemplate(template) {
            this.showMiniView = false;
            this.showFullView = false;
            this.templateComponent = require(`@/components/records/templates/${template}/details.vue`).default;
        },
    },
};
</script>
