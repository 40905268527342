<template>
    <div class="absolute bottom-3 flex justify-center w-full">
        <div class="bg-white p-6 rounded-lg w-full md:w-1/3">
            <component
                :is="descriptionComponent"
                :properties="properties"
                @close="close"
                @showFullView="showFullView"
                @open-template="openTemplate"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selectedRow: {
            type: Object,
            required: true,
        },
    },
    computed: {
        properties() {
            return this.selectedRow.properties;
        },
        descriptionFormat() {
            return this.properties.description_format || JSON.parse(this.properties.display_details).description_format;
        },
        descriptionComponent() {
            return require(`components/sharable-map/mini-view/${this.descriptionFormat}.vue`).default;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        showFullView() {
            this.$emit('showFullView');
        },
        openTemplate(template) {
            this.$emit('open-template', template);
        },
    },
};
</script>
