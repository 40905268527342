<template>
    <div class="absolute right-0 md:mr-2 md:py-6 flex flex-col justify-center h-full w-full md:w-1/4">
        <div class="bg-white p-6 rounded-lg shadow-2xl max-w-md flex flex-col h-full md:h-auto">
            <div class="flex flex-col gap-4">
                <div class="flex justify-between">
                    <div class="flex items-center gap-4">
                        <position :icon="layer.icon" :position="properties.order" large />

                        <div class="flex flex-col">
                            <p class="text-lg font-medium">{{ layer.name }}</p>
                            <p v-if="propertyName" class="text-sm text-gray-500">{{ propertyName }}</p>
                        </div>
                    </div>
                    <!-- close mini view -->
                    <div>
                        <r-icon name="times" icon-style="fas" size="lg" @click="close" />
                    </div>
                </div>
                <div class="flex flex-col gap-4">
                    <p v-if="hasPropertyDescription">
                        {{ propertyDescription }}
                    </p>
                    <hr />
                    <!-- image gallery div -->
                    <div v-if="hasGallery">
                        <div class="flex gap-4 items-center">
                            <r-icon name="image" icon-style="fas" size="lg" @click="close" class="mt-1" />
                            <span class="font-bold">Image Gallery</span>
                        </div>
                        <public-gallery :id="galleryId" />
                    </div>
                    <hr v-if="hasGallery" />
                    <!-- website Link -->
                    <div v-if="hasWebsiteUrl" class="flex flex-col gap-4">
                        <div class="flex gap-4 items-center">
                            <r-icon name="link" icon-style="fas" size="lg" @click="close" class="mt-1" />
                            <span class="font-bold">Links</span>
                        </div>
                        <button @click="gotoWebsite" class="bg-gray-200 w-full rounded-lg p-2">
                            <span class="font-semibold text-black">Visit Website</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { icon } from 'utils/helpers';
import { isEmpty } from 'utils/lodash';
import Position from 'components/layers/types/area_tour/components/position.vue';
import PublicGallery from 'components/layers/types/common/components/annotations/gallery/public-gallery';

export default {
    components: {
        Position,
        PublicGallery,
    },
    props: {
        selectedRow: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('map', ['layers']),
        properties() {
            return this.selectedRow.properties;
        },
        layerId() {
            return this.properties.json_layer_id || JSON.parse(this.properties.layer).id;
        },
        layer() {
            return this.layers.find((layer) => layer.id === this.layerId);
        },
        icon() {
            return this.layer.icon;
        },
        source() {
            return icon(this.icon);
        },
        alt() {
            return this.icon;
        },
        propertyName() {
            return this.properties.name;
        },
        propertyDescription() {
            return this.properties.description;
        },
        hasPropertyDescription() {
            return this.propertyDescription && this.propertyDescription !== 'null';
        },
        gallery() {
            return this.properties.gallery;
        },
        galleryId() {
            try {
                return this.gallery.id || JSON.parse(this.gallery).id;
            } catch (error) {
                return 0;
            }
        },
        hasGallery() {
            return this.galleryId > 0;
        },
        hasWebsiteUrl() {
            return this.properties.url_type == 'Website' && !isEmpty(this.properties.url);
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        gotoWebsite() {
            window.open(this.properties.url);
        },
    },
};
</script>
