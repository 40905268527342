<template>
    <div class="flex flex-col gap-4">
        <div class="flex justify-between">
            <div class="flex items-center gap-4">
                <position :icon="layer.icon" :position="properties.order" large />

                <div class="flex flex-col">
                    <p class="text-lg font-medium">{{ layer.name }}</p>
                    <p v-if="propertyName" class="text-sm text-gray-500">{{ propertyName }}</p>
                </div>
            </div>
            <!-- close mini view -->
            <div>
                <r-icon name="times" icon-style="fas" size="lg" @click="close" />
            </div>
        </div>
        <div class="flex flex-col gap-4">
            <p v-if="hasPropertyDescription" class="truncate">
                {{ propertyDescription }}
            </p>
            <!-- see more -->
            <button @click="showFullView">
                <div class="flex items-center cursor-pointer">See More <i class="fas fa-chevron-right ml-1"></i></div>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { icon } from 'utils/helpers';
import Position from 'components/layers/types/area_tour/components/position.vue';

export default {
    components: {
        Position,
    },
    props: {
        properties: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('map', ['layers']),
        layerId() {
            return this.properties.json_layer_id || JSON.parse(this.properties.layer).id;
        },
        layer() {
            return this.layers.find((layer) => layer.id === this.layerId);
        },
        icon() {
            return this.layer.icon;
        },
        source() {
            return icon(this.icon);
        },
        alt() {
            return this.icon;
        },
        propertyName() {
            return this.properties.name;
        },
        propertyDescription() {
            return this.properties.description;
        },
        hasPropertyDescription() {
            return this.propertyDescription && this.propertyDescription !== 'null';
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        showFullView() {
            this.$emit('showFullView');
        },
    },
};
</script>
