<template>
    <div class="flex justify-between">
        <a v-if="miniDetailUrl && !template" :href="miniDetailUrl" target="_blank" class="w-full">
            <div v-html="description" />
        </a>
        <div v-else class="flex flex-col gap-4 w-full">
            <div v-html="description" />
            <div v-if="template">
                <button @click="openTemplate">
                    <div class="flex items-center cursor-pointer">
                        See More <i class="fas fa-chevron-right ml-1"></i>
                    </div>
                </button>
            </div>
        </div>
        <r-icon name="times" icon-style="fas" size="lg" @click="close" />
    </div>
</template>

<script>
export default {
    props: {
        properties: {
            type: Object,
            required: true,
        },
    },
    computed: {
        displayDetails() {
            return JSON.parse(this.properties.display_details);
        },
        description() {
            return this.displayDetails.description;
        },
        miniDetailUrl() {
            return this.displayDetails.url;
        },
        template() {
            return this.displayDetails.template;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        openTemplate() {
            this.$emit('open-template', this.template);
        },
    },
};
</script>
