<template>
    <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-md flex flex-col">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">Commute Radius</p>
                <r-icon name="times" icon-style="fas" size="lg" @click.stop="close" />
            </div>

            <div class="flex items-center gap-2 mt-2">
                <r-icon class="mt-1" :name="profileIcon" icon-style="fas" size="lg" />
                <p class="text-base">{{ profile }}</p>
            </div>

            <div class="button-group text-lg not-italic">
                <commute-contour
                    v-for="contour in contours"
                    :key="contour.id"
                    :contour="contour"
                    :layer="layer"
                    @change="onContourSelected"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { replace } from 'utils/helpers';
import { cloneDeep } from 'utils/lodash';
import { mapActions, mapGetters } from 'vuex';
import CommuteContour from 'components/sharable-map/layers/layer/commute-radius/commute-contour/index.vue';
export default {
    components: {
        CommuteContour,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('map', ['isPublic']),
        name() {
            return this.layer.filters.profile;
        },
        contours() {
            return this.layer.filters.contours;
        },
        profile() {
            const profile = this.layer.filters.profile;
            return profile.charAt(0).toUpperCase() + profile.slice(1);
        },
        profileIcon() {
            switch (this.name) {
                case 'walking':
                    return 'walking';
                case 'driving':
                    return 'car';
                case 'cycling':
                    return 'bicycle';
                default:
                    return 'car';
            }
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
        close() {
            this.$emit('close');
        },
        async onContourSelected(contour) {
            let contours = cloneDeep(this.contours);
            replace(contours, { value: contour.value }, { ...contour, visible: !contour.visible });

            // check if all at least one contour is visible
            const isVisible = contours.some((contour) => contour.visible);

            if (this.isPublic) {
                await this.updateLayer({
                    ...this.layer,
                    filters: { ...this.layer.filters, contours: contours },
                    visible: isVisible,
                });
            } else {
                await this.saveLayer({
                    ...this.layer,
                    filters: { ...this.layer.filters, contours: contours },
                    visible: isVisible,
                });
            }
        },
    },
};
</script>
